
angular.module('app').run(['$router', function ($router) {
	return $router.when('/admin/logs').title('Logs').component('logListCtrl');
}]).component('logListCtrl', {
	templateUrl: '/units/middleware.logging.db/list.tmpl.html',
	controller: ['$scope', '$loader', '$toast', 'Logs', 'Users', function controller($scope, $loader, $toast, Logs, Users) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.query = {
			limit: 100,
			sort: '-created'
		};

		$ctrl.logs;
		$ctrl.logEntry; // Focused log entry (on click)
		$ctrl.users = {}; // Cached list of basic user info (by _id)
		$ctrl.refresh = function () {
			$loader.start($scope.$id, $ctrl.logs === undefined);

			Logs.query($ctrl.query).$promise.then(function (data) {
				return $ctrl.logs = data;
			}).then(function () {
				var fetchUsers = $ctrl.logs.filter(function (l) {
					return !!l.user;
				}).map(function (l) {
					return l.user;
				});

				if (fetchUsers) return Users.query({
					_id: fetchUsers,
					select: '_id,email,name'
				}).$promise.then(function (data) {
					return data.forEach(function (u) {
						return $ctrl.users[u._id] = u;
					});
				});
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};

		$ctrl.viewEntry = function (entry) {
			$loader.startBackground($scope.$id);

			Logs.get({ id: entry._id }).$promise.then(function (data) {
				return $ctrl.logEntry = entry;
			}).then(function () {
				return $('#modal-log-entry').modal('show');
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}]
});