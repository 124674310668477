
/**
* Display a list widget showing the users starred pages
*/
angular.module('app').component('starList', {
	controller: ['$loader', '$prompt', '$q', '$scope', '$session', '$toast', 'Stars', function controller($loader, $prompt, $q, $scope, $session, $toast, Stars) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.stars;
		$ctrl.refresh = function () {
			if (!$session.isLoggedIn) return;

			$q.resolve().then($loader.startBackground($scope.$id)).then(function () {
				return Stars.query().$promise;
			}).then(function (data) {
				return $ctrl.stars = data;
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		// Remove stars {{{
		$ctrl.removeStar = function (link) {
			return $q.resolve().then(function () {
				return $prompt.confirm({
					title: 'Remove star',
					body: 'Are you sure you want to remove this star?'
				});
			}).then(function () {
				return $loader.startBackground($scope.$id + '-remove');
			}).then(function () {
				return Stars.toggle({ link: link }).$promise;
			}).then(function () {
				return $ctrl.refresh();
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id + '-remove');
			});
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}],
	template: '\n\t\t\t<ul ng-if="$ctrl.stars.length" class="dropdown-menu">\n\t\t\t\t<li ng-repeat="star in $ctrl.stars">\n\t\t\t\t\t<a href="#{{star.link}}">\n\t\t\t\t\t\t<span ng-click="$event.preventDefault(); $ctrl.removeStar(star.link)" class="fa-stack fa-lg">\n\t\t\t\t\t\t\t<i class="fa fa-stack-1x fa-star star-outer"></i>\n\t\t\t\t\t\t\t<i class="far fa-stack-1x fa-star star-inner"></i>\n\t\t\t\t\t\t</span>\n\t\t\t\t\t\t<span ng-if="star.breadcrumbs">\n\t\t\t\t\t\t\t<ol class="breadcrumb">\n\t\t\t\t\t\t\t\t<li ng-repeat="breadcrumb in star.breadcrumbs">\n\t\t\t\t\t\t\t\t\t{{breadcrumb.title}}\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<span ng-if="!star.breadcrumbs && star.title">{{star.title}}</span>\n\t\t\t\t\t\t<span ng-if="!star.breadcrumbs && !star.title">{{star.link}}</span>\n\t\t\t\t\t</a>\n\t\t\t\t</li>\n\t\t\t</ul>\n\t\t\t<div ng-if="!$ctrl.stars.length" class="alert alert-info">\n\t\t\t\tYou have no starred items. Click the star icon in the top-right of pages to add them here.\n\t\t\t</div>\n\t\t'
});