
angular.module('app').run(['$router', function ($router) {
	return $router.when('/notifications/:id').title('View Notification').component('notificationsViewCtrl');
}]).component('notificationsViewCtrl', {
	templateUrl: '/units/notifications/view.tmpl.html',
	controller: ['$location', '$loader', '$router', '$scope', '$toast', 'Notifications', function controller($location, $loader, $router, $scope, $toast, Notifications) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.notification;
		$ctrl.refresh = function () {
			$loader.start($scope.$id);

			Notifications.get({
				id: $router.params.id,
				populate: 'from'
			}).$promise.then(function (data) {
				$ctrl.notification = data;
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		// Save {{{
		/**
  * Save a notification back
  * @param {Object} fields Fields to save
  */
		$ctrl.save = function (fields) {
			$loader.startBackground($scope.$id);
			Notifications.save({ id: $router.params.id }, fields).$promise.then(function () {
				return $location.path('/notifications');
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}]
});