
angular.module('app').component('docsSidebar', {
	controller: ['$rootScope', '$router', function controller($rootScope, $router) {
		var _this = this;

		var $ctrl = this;
		$ctrl.areas; // Array of each section of the route (seperated by -; e.g. '#/foo/bar' => [foo,bar])
		$ctrl.area; // First segment of $scope.areas

		$rootScope.$on('$routerSuccess', function () {
			if (!$router.path) return; // Router not ready yet
			$ctrl.areas = $router.path.split('/').slice(2); // Split into path segments (remove first empty element & '/docs' prefix)
			$ctrl.area = _this.areas[0];
		});
	}],
	template: '\n\t\t\t<ul class="main-menu">\n\t\t\t\t<li ng-class="!$ctrl.area && \'active\'">\n\t\t\t\t\t<a href="#/">\n\t\t\t\t\t\t<i class="fa fa-fw fa-tachometer-alt"></i>\n\t\t\t\t\t\tDocs Home\n\t\t\t\t\t</a>\n\t\t\t\t</li>\n\n\t\t\t\t<li ng-class="$ctrl.area==\'erd\' && \'active\'">\n\t\t\t\t\t<a href="#/docs/erd">\n\t\t\t\t\t\t<i class="fa fa-fw fa-sitemap"></i>\n\t\t\t\t\t\tDatabase ERD\n\t\t\t\t\t</a>\n\t\t\t\t</li>\n\t\t\t</ul>\n\t\t'
});