
/**
* Directive to display a <select/> compatible control but with lazy-loaded ajax content
*
* @param {boolean} [clearable=true] Whether the directive should support clearing the value
* @param {boolean} [multiple=false] Whether to allow multiple elements to be selected, the binding will be an array (of whatever is in `want`) if this is true
* @param {string} [ngModel] The item to bind to. The exact nature of what this gets is dictated by `want`
* @param {string} url Angular templatable string to where to fetch a collection of data from
* @param {function} [onSelect] Function to call when an item has been selected. Called as ({title, id})
* @param {string} [want="id"] How to set the value of ngModel. ENUM: 'id' (the ID of the selected item), 'object' (the entire selected object including {id, title})
*
* @example Display dropdown entity
* <select-from url="/api/dropdowns/byName/designPackage"></select-from>
*/
angular.module('app').component('selectFrom', {
	bindings: {
		ngModel: '=?',
		onSelect: '&?',
		multiple: '<?',
		url: '@?',
		want: '@?'
	},
	controller: ['$element', '$http', '$loader', '$prompt', '$q', '$scope', '$timeout', '$toast', function controller($element, $http, $loader, $prompt, $q, $scope, $timeout, $toast) {
		var $ctrl = this;

		$ctrl.selected; // Object the user has selected (expected to have {id,title}
		$ctrl.searchString = ''; // What the user is searching for, when something is selected this inherits the $ctrl.selected.title

		$ctrl.options; // Available options - lazy loaded from the URL when needed
		$ctrl.optionsFiltered = $ctrl.options; // Filtered version of $ctrl.options when being searched

		// .refresh() - data refresher() {{{
		$ctrl.isLoading = false;
		$ctrl.isLoaded = false; // Whether we have run $ctrl.refresh() at least once
		$ctrl.refresh = function () {
			var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

			if (!force && $ctrl.isLoaded) return $q.resolve(); // Already loaded data

			return $q.resolve().then(function () {
				return $ctrl.isLoading = true;
			}).then(function () {
				return $ctrl.isLoaded = true;
			}).then(function () {
				return $loader.startBackground($scope.$id);
			}).then(function () {
				if (!$ctrl.url) throw new Error('No URL to resolve when refreshing selectFrom directive');
			}).then(function () {
				return $http.get($ctrl.url);
			}).then(function (res) {
				return res.data;
			})
			// Convert data if needed {{{
			.then(function (data) {
				if (data.options) {
					// Dropdown format
					return data.options.map(function (o) {
						return {
							title: o.description,
							id: o.dropdownId || o.description
						};
					});
				} else if (!angular.isArray(data) || data.some(function (d) {
					return d.title && d.id;
				})) {
					throw new Error('Unknown data format returned when using selectFrom directive!');
				} else {
					return data;
				}
			})
			// }}}
			.then(function (data) {
				return $ctrl.optionsFiltered = $ctrl.options = data;
			})
			// If we are in multiple selection mode, and already have a list of selected items - correctly set them to selected {{{
			.then(function () {
				if (!$ctrl.multiple || !angular.isArray($ctrl.ngModel)) return;

				$ctrl.options.filter(function (i) {
					return $ctrl.ngModel.includes(i.id);
				}).forEach(function (i) {
					return $ctrl.setSelected(i);
				});
			})
			// }}}
			// Convert $ctrl.selected from a scalar into an object {{{
			.then(function () {
				if (!angular.isObject($ctrl.selected)) {
					$ctrl.selected = $ctrl.options.find(function (o) {
						return o.id == $ctrl.selected;
					});
				}
			})
			// }}}
			.catch($toast.catch).finally(function () {
				return $ctrl.isLoading = false;
			}).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		// .openDropdown() - deal with the dropdown loading + interaction {{{
		$ctrl.isDropdownOpen = false;
		$ctrl.openDropdown = function () {
			return $ctrl.refresh().then(function () {
				return $ctrl.isDropdownOpen = true;
			}).then(function () {
				return $ctrl.searchString = '';
			}).then(function () {
				return $ctrl.search();
			}).then(function () {
				return $q(function (resolve) {
					return $timeout(function () {
						return resolve();
					}, 100);
				});
			}) // Let Angular render everything
			.then(function () {
				return $prompt.dropdown({
					dropdown: $element.find('.dropdown-menu'),
					element: $element.find('input[type=text]'),
					marginTop: 10
				});
			}).then(function () {
				if (angular.isFunction($ctrl.onSelect)) $ctrl.onSelect($ctrl.selected);
			}).finally(function () {
				return $ctrl.isDropdownOpen = false;
			});
		};
		// }}}

		// .search() - filter down the dropdown contents {{{
		$ctrl.search = function () {
			if ($ctrl.searchString) {
				var searchRE = new RegExp($ctrl.searchString.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'), 'i');
				$ctrl.optionsFiltered = $ctrl.options.filter(function (o) {
					return searchRE.test(o.title);
				});
			} else {
				$ctrl.optionsFiltered = $ctrl.options;
			}
		};
		// }}}

		// .setSelected() - actually select things {{{
		$ctrl.setSelected = function (item) {
			if (!$ctrl.multiple) {
				// Single item selection
				$ctrl.selected = item;
				$ctrl.searchString = $ctrl.selected ? $ctrl.selected.title : '';

				if ($ctrl.selected) {
					if (angular.isUndefined($ctrl.want) || $ctrl.want == 'id') {
						$ctrl.ngModel = $ctrl.selected.id;
					} else if ($ctrl.want == 'object') {
						$ctrl.ngModel = $ctrl.selected;
					} else {
						throw new Error('Unknown `want` value for selectFrom directive');
					}
				} else {
					$ctrl.ngModel = undefined;
				}
			} else {
				if (item) {
					item.selected = !item.selected;
					$ctrl.selected = $ctrl.options.filter(function (i) {
						return i.selected;
					});
				} else {
					// Assume we meant to clear instead
					$ctrl.options.forEach(function (i) {
						return i.selected = false;
					});
					$ctrl.selected = [];
				}

				$ctrl.searchString = '';
				$ctrl.searchPlaceholder = $ctrl.selected.map(function (i) {
					return i.title;
				}).join(', ');

				if ($ctrl.selected.length) {
					if (angular.isUndefined($ctrl.want) || $ctrl.want == 'id') {
						$ctrl.ngModel = $ctrl.selected.map(function (i) {
							return i.id;
						});
					} else if ($ctrl.want == 'object') {
						$ctrl.ngModel = $ctrl.selected;
					} else {
						throw new Error('Unknown `want` value for selectFrom directive');
					}
				} else {
					$ctrl.ngModel = [];
				}
			}
		};
		// }}}

		// Watch: $ctrl.ngModel - calculate search string {{{
		$scope.$watch('$ctrl.ngModel', function () {
			$ctrl.searchString = angular.isArray($ctrl.ngModel) ? $ctrl.ngModel.join(', ') : angular.isObject($ctrl.ngModel) ? $ctrl.ngModel.title : $ctrl.ngModel;
		});
		// }}}
	}],
	template: '\n\t\t\t<div class="btn-group" ng-class="$ctrl.isLoading ? \'loading\' : \'\'">\n\t\t\t\t<a ng-click="$ctrl.openDropdown()" class="btn btn-default">\n\t\t\t\t\t<input type="text" ng-model="$ctrl.searchString" placeholder="{{$ctrl.searchPlaceholder}}" ng-change="$ctrl.search()"/>\n\t\t\t\t\t<button ng-click="$event.stopPropagation(); $ctrl.setSelected()" class="clear" ng-show="$ctrl.searchString" tooltip="Clear selection"></button>\n\t\t\t\t</a>\n\t\t\t\t<ul ng-if="$ctrl.isDropdownOpen" class="dropdown-menu">\n\t\t\t\t\t<li ng-if="$ctrl.clearable === undefined || $ctrl.clearable">\n\t\t\t\t\t\t<a ng-click="$ctrl.setSelected()">\n\t\t\t\t\t\t\t<em>Clear selection</em>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</li>\n\t\t\t\t\t<li ng-if="!$ctrl.multiple" ng-repeat="option in $ctrl.optionsFiltered track by option.id">\n\t\t\t\t\t\t<a ng-click="$ctrl.setSelected(option)" ng-bind="option.title"></a>\n\t\t\t\t\t</li>\n\t\t\t\t\t<li ng-if="$ctrl.multiple" ng-repeat="option in $ctrl.optionsFiltered track by option.id">\n\t\t\t\t\t\t<a ng-click="$ctrl.setSelected(option); $event.stopPropagation()">\n\t\t\t\t\t\t\t<i ng-class="option.selected ? \'far fa-fw fa-lg fa-check-square\' : \'far fa-fw fa-lg fa-square\'"></i>\n\t\t\t\t\t\t\t{{option.title}}\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</li>\n\t\t\t\t</ul>\n\t\t\t</div>\n\t\t'
});