
/**
* Display a list of all file uploads handled by the $filekit.upload() function
*
* @example Display a list of uploading files
* <filekit-upload-status></filekit-upload-status>
*/
angular.module('app').component('filekitUploadStatus', {
	controller: ['$filekit', function controller($filekit) {
		var $ctrl = this;
		$ctrl.$filekit = $filekit;
	}],
	template: '\n\t\t\t<div ng-if="$ctrl.$filekit.settings.uploadStatus.enabled" ng-repeat="(id, upload) in $ctrl.$filekit.uploading track by id" class="row">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<div class="card">\n\t\t\t\t\t\t<div class="card-body p-10">\n\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t<div class="pull-right">\n\t\t\t\t\t\t\t\t\t<span class="badge badge-muted">{{upload.file.size | filesize}}</span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t{{upload.file.name}}\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="progress">\n\t\t\t\t\t\t\t\t<div\n\t\t\t\t\t\t\t\t\tclass="progress-bar"\n\t\t\t\t\t\t\t\t\tstyle="width: {{upload.progress}}%"\n\t\t\t\t\t\t\t\t\tng-class="{\n\t\t\t\t\t\t\t\t\t\t\'progress-bar-success\' : upload.status == \'done\',\n\t\t\t\t\t\t\t\t\t\t\'progress-bar-danger\': upload.status == \'error\',\n\t\t\t\t\t\t\t\t\t}"\n\t\t\t\t\t\t\t\t></div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});