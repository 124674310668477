
angular.module('app').component('debugNavbar', {
	controller: ['$prompt', '$rootScope', '$scope', '$session', '$toast', '$window', 'Users', function controller($prompt, $rootScope, $scope, $session, $toast, $window, Users) {
		var $ctrl = this;

		$ctrl.profileReload = function () {
			return $session.update().then(function () {
				return $toast.success('Profile reloaded');
			});
		};

		$ctrl.disguise = function (type) {
			$session.data.isAdmin = false;
			$session.data.isConsultant = false;
			$session.data.isContractor = false;
			$session.data.isIndependentCertifier = false;
			$session.data.isPrimaryContractor = false;
			$session.data.isSupplier = false;
			$session.data.isRoot = false;

			$session.data.isDisguised = true;

			// Reset all permissions
			$session.data.permissions = _.mapValues($session.data.permissions, function () {
				return false;
			});

			switch (type) {
				case 'clear':
					$session.data.bio = 'Disguised as a blank user';
					break;
				case 'consultant':
					$session.data.isConsultant = true;
					$session.data.bio = 'Disguised as a consultant';
					break;
				case 'contractor':
					$session.data.isContractor = true;
					$session.data.bio = 'Disguised as a contractor';
					break;
				case 'independentCertifier':
					$session.data.isIndependentCertifier = true;
					$session.data.bio = 'Disguised as a independent certifier';
					break;
				case 'primaryContractor':
					$session.data.isPrimaryContractor = true;
					$session.data.bio = 'Disguised as a primary contractor';
					break;
				case 'supplier':
					$session.data.isSupplier = true;
					$session.data.bio = 'Disguised as a supplier';
					break;
				default:
					throw new Error('Unknown disguise type: "' + type + '"');
			}
			$rootScope.$broadcast('session.updated', $session.data);
			$toast.success('You are now ' + $session.data.bio.toLowerCase());
		};

		$ctrl.editPermissions = function () {
			Users.meta().$promise.then(function (meta) {
				return $prompt.list({
					title: 'Set permissions',
					multiple: true,
					list: _(meta).pickBy(function (v, k) {
						return (/^permissions\./.test(k)
						);
					}).map(function (v, k) {
						return k.replace(/^permissions\./, '');
					}).map(function (v) {
						return { id: v, title: v };
					}).value(),
					default: _($session.data.permissions).pickBy(function (v, k) {
						return !!v;
					}).map(function (v, k) {
						return k;
					}).value()
				});
			}).then(function (permissions) {
				$session.data.permissions = _(permissions).mapKeys(function (v) {
					return v;
				}).mapValues(function () {
					return true;
				}).value();
			}).then(function () {
				return $toast.success('Set ' + _.keys($session.data.permissions).length + ' permissions');
			});
		};

		/**
  * Redirect an absolute URL to localhost
  */
		$ctrl.redirectToLocal = function () {
			return $window.location = 'http://localhost:8080/' + $window.location.hash;
		};
	}],
	template: '\n\t\t\t<ul class="nav navbar-top-links pull-right">\n\t\t\t\t<li id="dropdown-user" class="dropdown">\n\t\t\t\t\t<a data-toggle="dropdown" class="dropdown-toggle text-right">\n\t\t\t\t\t\t<div class="username hidden-xs text-primary">Admin</div>\n\t\t\t\t\t</a>\n\t\t\t\t\t<div class="dropdown-menu dropdown-menu-md dropdown-menu-right panel-default">\n\t\t\t\t\t\t<ul class="head-list">\n\t\t\t\t\t\t\t<li><a href="https://papertrailapp.com/events" target="_blank">Papertrail Server Logs</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.redirectToLocal()">Redirect to localhost</a></li>\n\t\t\t\t\t\t\t<li class="divider"></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.disguise(\'clear\')">Clear all permissions</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.disguise(\'consultant\')">Disguise as a consultant</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.disguise(\'contractor\')">Disguise as a contractor</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.disguise(\'independentCertifier\')">Disguise as an independent certifier</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.disguise(\'primaryContractor\')">Disguise as a primary contractor</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.disguise(\'supplier\')">Disguise as a supplier</a></li>\n\t\t\t\t\t\t\t<li><a href="#/admin/users">Disguise as user...</a></li>\n\t\t\t\t\t\t\t<li class="divider"></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.editPermissions()">Edit Permissions...</a></li>\n\t\t\t\t\t\t\t<li><a ng-click="$ctrl.profileReload()">Reload Profile</a></li>\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</div>\n\t\t\t\t</li>\n\t\t\t</ul>\n\t\t'
});