
angular.module('app').run(['$router', function ($router) {
	return $router.when('/dashboard').title('Dashboard').component('dashboardCtrl');
}]).component('dashboardCtrl', {
	templateUrl: '/units/dashboard/dashboard.tmpl.html',
	controller: ['$scope', function controller($scope) {
		var $ctrl = this;

		$ctrl.refresh = function () {};

		$scope.$evalAsync($ctrl.refresh);
	}]
});