
/**
* Generic routing helper that automatically creates a folder display based on the requeted (and not present) routing path
* For example, if requesting `/foo` and nothing matches that rule, but it does have children in the sitemap those children will be displayed in folder form
*/

angular.module('app').run(['$q', '$router', '$sitemap', 'TreeTools', function ($q, $router, $sitemap, TreeTools) {
	return $router.rule().priority('low').title('Select area').requires(function (path) {
		return $q(function (resolve, reject) {
			// Check that this path is valid within the sitemap
			$sitemap.get().then(function (map) {
				var branch = TreeTools.find($sitemap.map, { href: '#' + path });
				if (branch && branch.children && branch.children.length) {
					resolve();
				} else {
					reject();
				}
			});
		});
	}).component('folderLayoutCtrl');
}]).component('folderLayoutCtrl', {
	controller: ['$rootScope', '$router', '$sitemap', 'TreeTools', function controller($rootScope, $router, $sitemap, TreeTools) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.refresh = function () {
			$ctrl.branch = TreeTools.find($sitemap.map, { href: '#' + $router.path });
		};
		// }}}

		$rootScope.$on('$routerSuccess', function () {
			return $ctrl.refresh();
		});
	}],
	template: '\n\t\t\t<div id="page-title">\n\t\t\t\t<h1 class="page-header text-overflow">{{$ctrl.branch.title}}</h1>\n\t\t\t</div>\n\t\t\t<div id="page-content">\n\t\t\t\t<div class="row">\n\t\t\t\t\t<a ng-repeat="folder in $ctrl.branch.children" href="{{folder.href}}" class="col-sm-6 col-lg-4">\n\t\t\t\t\t\t<div class="panel panel-clip-sm media pad-all">\n\t\t\t\t\t\t\t<div class="media-left">\n\t\t\t\t\t\t\t\t<span class="icon-wrap icon-wrap-sm icon-circle bg-primary">\n\t\t\t\t\t\t\t\t\t<i class="fa fa-folder fa-2x"></i>\n\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t<div class="media-body">\n\t\t\t\t\t\t\t\t<p class="h2 mar-no text-thin">{{folder.title}}</p>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</a>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});