
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/').title('Home').requires($session.promise.notLogin).component('homeCtrl');
}]).component('homeCtrl', {
	templateUrl: '/units/site/index.tmpl.html',
	controller: ['$scope', function controller($scope) {
		var $ctrl = this;

		$ctrl.refresh = function () {};

		$scope.$evalAsync($ctrl.refresh);
	}]
});