
angular.module('app').run(['$router', function ($router) {
	return $router.when('/docs/erd').component('docsERDCtrl');
}]).component('docsERDCtrl', {
	controller: ['$loader', '$http', '$scope', '$toast', function controller($loader, $http, $scope, $toast) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.refresh = function () {
			$loader.startBackground($scope.$id);
			$http.get('/api/docs/erd/erd.txt').then(function (res) {
				return $ctrl.schema = res.data;
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}],
	template: '\n\t\t\t<div class="container">\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading"><h2>ERD Diagram</h2></div>\n\t\t\t\t\t<div class="panel-body">\n\t\t\t\t\t\t<ul class="nav nav-tabs">\n\t\t\t\t\t\t\t<li class="active"><a data-target="#erd" data-toggle="tab">Diagram</a></li>\n\t\t\t\t\t\t\t<li><a data-target="#markdown" data-toggle="tab">UML Schema</a></li>\n\t\t\t\t\t\t\t<li class="pull-right"><a ng-click="$ctrl.refresh"><i class="fa fa-sync"></i></a></li>\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t<div class="tab-content" style="overflow: auto">\n\t\t\t\t\t\t\t<div class="tab-pane active" id="erd">\n\t\t\t\t\t\t\t\t<img src="/api/docs/erd/erd.svg"/>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="tab-pane" id="markdown">\n\t\t\t\t\t\t\t\t<pre>{{$ctrl.schema}}</pre>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});