
/**
* Simple user display widget
* NOTE: This is a bind-once widget, any changes to the user object will NOT be updated
* @param {Object|string} user The user to display. If this is an object it will be used as is and should contain the below. If its a string its assumed to be the user ID and a server query will be done to fetch the user information.
* @param {string} [user.email] The users email address
* @param {string} [user.name] The users name
* @param {boolean} [name=false] Display the users name next to the avatar
*/
angular.module('app').component('user', {
	bindings: {
		user: '<',
		name: '<'
	},
	controller: ['$element', '$scope', '$session', 'Users', function controller($element, $scope, $session, Users) {
		var $ctrl = this;
		$ctrl.$session = $session;

		$ctrl.userObject;
		$scope.$watch('$ctrl.user', function () {
			if (!$ctrl.user) {
				return; // Not yet loaded
			} else if (angular.isObject($ctrl.user)) {
				$ctrl.userObject = $ctrl.user;
			} else if (angular.isString($ctrl.user)) {
				Users.get({ id: $ctrl.user, select: 'email,name' }).$promise.then(function (data) {
					return $ctrl.userObject = data;
				});
			}
		});

		// Popover {{{
		$ctrl.popover = {
			enabled: false,
			top: 0,
			left: 0,
			height: 200,
			width: 250
		};

		$element.on('mouseover', function () {
			return $scope.$apply(function () {
				$ctrl.popover.enabled = true;
				var pos = $($element).offset();
				$ctrl.popover.top = pos.top - ($ctrl.popover.height + 30);
				$ctrl.popover.left = pos.left - $ctrl.popover.width / 2 + 20;
			});
		}).on('mouseleave', function () {
			return $scope.$apply(function () {
				return $ctrl.popover.enabled = false;
			});
		});

		// Hide the popover if the user starts scrolling again
		var scrollHider = function scrollHider() {
			return $scope.$apply(function () {
				return $ctrl.popover.enabled = false;
			});
		}; // We need to store this in a function ref so we can detach when this directive gets destroyed
		angular.element('#content').on('scroll', scrollHider);
		$scope.$on('$destroy', function () {
			return angular.element('#content').off('scroll', scrollHider);
		});
		// }}}
	}],
	template: '\n\t\t\t<i ng-if="!$ctrl.userObject._id" class="fa fa-spinner fa-spin fa-2x"></i>\n\t\t\t<a ng-if="$ctrl.userObject._id" ng-href="{{$ctrl.$session.data.permissions.users ? \'#/admin/users/\' + $ctrl.userObject._id : undefined}}" class="media">\n\t\t\t\t<div ng-if="$ctrl.popover.enabled" class="popover top in" role="tooltip" style="position: fixed; top: {{$ctrl.popover.top}}px; left: {{$ctrl.popover.left}}px; display: block; width: {{$ctrl.popover.width}}px; height: {{$ctrl.popover.height}}px">\n\t\t\t\t\t<div class="arrow" style="left: 50.2538%;"></div>\n\t\t\t\t\t<h3 class="popover-title" style="display: none;"></h3>\n\t\t\t\t\t<div class="popover-content text-black">\n\t\t\t\t\t\t<div class="text-center">\n\t\t\t\t\t\t\t<div style="display: block; width: 120px; height: 120px; margin: 0 auto">\n\t\t\t\t\t\t\t\t<img gravatar-src-once="::$ctrl.userObject.email" gravatar-size="120" gravatar-default="monsterid" class="img-circle img-user media-object">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="m-t-20">\n\t\t\t\t\t\t\t\t<strong>\n\t\t\t\t\t\t\t\t\t{{::$ctrl.userObject.name}}\n\t\t\t\t\t\t\t\t</strong>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="m-t-5">\n\t\t\t\t\t\t\t\t{{::$ctrl.userObject.email}}\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="media-left">\n\t\t\t\t\t<img gravatar-src-once="$ctrl.userObject.email" gravatar-size="40" gravatar-default="monsterid" class="img-circle img-user media-object"/>\n\t\t\t\t</div>\n\t\t\t\t<div ng-if="::$ctrl.name" class="media-body">\n\t\t\t\t\t<div class="media-right p-t-5">\n\t\t\t\t\t\t{{::$ctrl.userObject.name}}\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</a>\n\t\t'
});