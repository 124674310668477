
angular.module('app').run(['$router', function ($router) {
	return $router.when('/debug/directives/icons').component('debugDirectivesIcons');
}]).component('debugDirectivesIcons', {
	controller: ['$http', '$loader', '$q', '$scope', '$timeout', '$toast', function controller($http, $loader, $q, $scope, $timeout, $toast) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.icons;
		$ctrl.refresh = function () {
			return $q.resolve().then(function () {
				return $loader.start($scope.$id);
			}).then(function () {
				return $http.get('/webfonts/fa.json');
			}).then(function (res) {
				return $ctrl.icons = res.data;
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}],
	template: '\n\t\t\t<ol class="breadcrumb">\n\t\t\t\t<li><a href="#/debug">Debugging</a></li>\n\t\t\t\t<li><a href="#/debug/directives">Directives</a></li>\n\t\t\t\t<li><a href="#/debug/directives/icons">icons</a></li>\n\t\t\t</ol>\n\t\t\t<div id="page-content">\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading">\n\t\t\t\t\t\t<h3 class="panel-title">Icons</h3>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="panel-body">\n\t\t\t\t\t\t<p>Simple font-awesome icon importer.</p>\n\t\t\t\t\t\t<p>Use icons by specifying the class <code>fa</code> (bold), <code>far</code> (regular weight) or <code>fal</code> (light weight) with a single icon class.</p>\n\t\t\t\t\t\t<p><code>&lt;i class="fa fa-lemon"&gt;&lt;/i&gt;</code></p>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading">\n\t\t\t\t\t\t<h3 class="panel-title">Icons</h3>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="panel-body form-horizontal btn-group-folders">\n\t\t\t\t\t\t<div ng-repeat="icon in $ctrl.icons track by icon.id" class="col-sm-4 col-lg-3">\n\t\t\t\t\t\t\t<a class="btn btn-block media">\n\t\t\t\t\t\t\t\t<div class="media-left">\n\t\t\t\t\t\t\t\t\t<span class="icon-wrap icon-wrap-sm icon-circle bg-primary">\n\t\t\t\t\t\t\t\t\t\t<i class="fa-fw fa-2x" ng-class="icon.class"></i>\n\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="media-body">\n\t\t\t\t\t\t\t\t\t<h2>{{icon.id}}</h2>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});