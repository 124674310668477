
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/translations').title('Translations and adaptations').requires($session.promise.notLogin).component('translationsPageCtrl');
}]).component('translationsPageCtrl', {
	templateUrl: '/units/site/translations.tmpl.html',
	controller: ['$scope', function controller($scope) {
		var $ctrl = this;

		$ctrl.refresh = function () {};

		$scope.$evalAsync($ctrl.refresh);
	}]
});