
/**
* Starring widget
* If the user stars something the link to it appears in their $session.user.stars[]
* This is effectively a bookmark
*
* @example
* <star></star>
*/
angular.module('app').component('star', {
	controller: ['$loader', '$location', '$q', '$router', '$scope', '$toast', 'Stars', function controller($loader, $location, $q, $router, $scope, $toast, Stars) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.star; // Object containing .isStarred
		$ctrl.refresh = function () {
			return $q.resolve().then(function () {
				return $loader.startBackground($scope.$id);
			}).then(function () {
				return Stars.get({ link: $location.path() }).$promise;
			}).then(function (data) {
				return $ctrl.star = data;
			}).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
		// }}}

		// Toggle star {{{
		$ctrl.toggleStar = function () {
			return $q.resolve().then(function () {
				return $loader.startBackground($scope.$id + '-toggle');
			}).then(function () {
				return Stars.toggle({
					link: $location.path(),
					title: $router.page._title,
					breadcrumbs: $router.page._breadcrumbs
				}).$promise;
			}).then(function () {
				return $ctrl.refresh();
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id + '-toggle');
			});
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}],
	template: '\n\t\t\t<a ng-click="$ctrl.toggleStar()" class="pull-right btn btn-link" ng-class="$ctrl.star.isStarred ? \'active\' : \'\'">\n\t\t\t\t<span class="fa-stack fa-lg">\n\t\t\t\t\t<i ng-if="$ctrl.star" class="fa fa-stack-1x fa-star star-outer"></i>\n\t\t\t\t\t<i class="far fa-stack-1x fa-star star-inner"></i>\n\t\t\t\t</span>\n\t\t\t</a>\n\t\t'
});