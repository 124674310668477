
angular.module('app').run(['$router', function ($router) {
	return $router.when('/logout').title('Logout').component('sessionLogoutCtrl');
}]).component('sessionLogoutCtrl', {
	templateUrl: '/units/session/logout.tmpl.html',
	controller: ['$location', '$session', '$timeout', function controller($location, $session, $timeout) {
		var $ctrl = this;

		$timeout(function () {
			// Wait a second for everything to settle then logout
			$session.logout().then(function () {
				return $location.redirect('/');
			});
		}, 1000);
	}]
});