
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/info').title('More about TIDieR').requires($session.promise.notLogin).component('infoPageCtrl');
}]).component('infoPageCtrl', {
	templateUrl: '/units/site/info.tmpl.html',
	controller: ['$scope', function controller($scope) {
		var $ctrl = this;

		$ctrl.refresh = function () {};

		$scope.$evalAsync($ctrl.refresh);
	}]
});