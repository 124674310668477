
angular.module('app').run(['$router', function ($router) {
	return $router.when('/debug/directives/reveal').component('debugDirectivesReveal');
}]).component('debugDirectivesReveal', {
	controller: ['$timeout', function controller($timeout) {
		var $ctrl = this;

		$ctrl.isRevealed = {};
		$ctrl.triggerReveal = function (id) {
			console.log('REVEAL', id);
			$ctrl.isRevealed[id] = true;
			$timeout(function () {
				return $ctrl.isRevealed[id] = 'idle';
			}, 500);
		};
	}],
	template: '\n\t\t\t<ol class="breadcrumb">\n\t\t\t\t<li><a href="#/debug">Debugging</a></li>\n\t\t\t\t<li><a href="#/debug/directives">Directives</a></li>\n\t\t\t\t<li><a href="#/debug/directives/reveal">reveal</a></li>\n\t\t\t</ol>\n\t\t\t<div id="page-content">\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading">\n\t\t\t\t\t\t<h3 class="panel-title">reveal directive</h3>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="panel-body">\n\t\t\t\t\t\t<p>A directive that can be attached to any element to run a function when the element becomes visible or hidden on the screen.</p>\n\t\t\t\t\t\t<p>This can be used to defer loading of data unless its needed - for example in infinite scrolling displays.</p>\n\t\t\t\t\t\t<p><code>&lt;element reveal="functionToRun()"&gt;&lt;/element&gt;</code></p>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading">\n\t\t\t\t\t\t<h3 class="panel-title">Demo</h3>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="panel-body form-horizontal">\n\t\t\t\t\t\t<div ng-repeat="n in [].constructor(50) track by $index" class="form-group">\n\t\t\t\t\t\t\t<label class="col-sm-3 control-label">Item {{$index}}</label>\n\t\t\t\t\t\t\t<div class="col-sm-9">\n\t\t\t\t\t\t\t\t<a\n\t\t\t\t\t\t\t\t\treveal="$ctrl.triggerReveal($index)"\n\t\t\t\t\t\t\t\t\tclass="btn"\n\t\t\t\t\t\t\t\t\tng-class="\n\t\t\t\t\t\t\t\t\t\t$ctrl.isRevealed[$index] == \'idle\' ? \'btn-default\'\n\t\t\t\t\t\t\t\t\t\t: $ctrl.isRevealed[$index] == true ? \'btn-success\'\n\t\t\t\t\t\t\t\t\t\t: \'btn-danger\'\n\t\t\t\t\t\t\t\t\t"\n\t\t\t\t\t\t\t\t>\n\t\t\t\t\t\t\t\t\tReveal element {{$index}}\n\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});