
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/profile').title('Profile').requires($session.promise.login).component('sessionProfileCtrl');
}]).component('sessionProfileCtrl', {
	templateUrl: '/units/session/profile.tmpl.html',
	controller: ['$scope', '$location', '$loader', '$session', '$timeout', '$toast', 'Users', function controller($scope, $location, $loader, $session, $timeout, $toast, Users) {
		var $ctrl = this;
		$ctrl.error;

		// Data refresher {{{
		$ctrl.meta;
		$ctrl.user = $session.data;
		$ctrl.refresh = function () {
			if (!$ctrl.meta) {
				// Not asked the server for meta data yet
				$loader.start($scope.$id, $ctrl.user === undefined);

				Users.meta().$promise.then(function (data) {
					return $ctrl.meta = data;
				}).finally(function () {
					return $loader.stop($scope.$id);
				});
			}
		};
		// }}}

		// Save {{{
		$ctrl.save = function () {
			if ($ctrl.passwordUnlock && !$ctrl.user.password) {
				$ctrl.passwordUnlock = false;
				delete $ctrl.user.password;
				delete $ctrl.user.password2;
			} else if ($ctrl.user.password && $ctrl.user.password != $ctrl.user.password2) {
				$ctrl.error = 'Your passwords must match';
			} else {
				delete $ctrl.user.password2;

				$loader.start($scope.$id);
				Users.save({ id: $session.data._id }, $ctrl.user).$promise.then(function () {
					return $toast.success('Profile saved');
				}).then(function () {
					return $location.path('/');
				}).then(function () {
					return $session.update();
				}).catch($toast.catch).finally(function () {
					return $loader.stop($scope.$id);
				});
			}
		};
		// }}}

		// Password setting {{{
		$ctrl.passwordUnlock = false;
		$ctrl.togglePasswordUnlock = function () {
			return $ctrl.passwordUnlock = !$ctrl.passwordUnlock;
		};
		// }}}

		$scope.$evalAsync($ctrl.refresh);
	}]
});