
/**
* Fire a $rootScope.$broadcast() with the given message when an ng-repeat render finishes
* @param {string} message The message to broadcast to $rootScope
* @example
* <div ng-repeat="widget in widgets" ng-repeat-broadcast="finished"></div>
*/
angular.module('app').directive('ngRepeatBroadcast', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
	return {
		restrict: 'A',
		link: function link(scope, elem, attr) {
			if (scope.$last === true) $timeout(function () {
				return $rootScope.$emit(attr.ngRepeatBroadcast);
			});
		}
	};
}]);