
angular.module('app').run(['$router', function ($router) {
	return $router.when('/debug/server/info').component('debugServerInfoCtrl');
}]).component('debugServerInfoCtrl', {
	controller: ['$http', '$loader', '$q', '$scope', '$timeout', '$toast', function controller($http, $loader, $q, $scope, $timeout, $toast) {
		var $ctrl = this;

		// Data refresher {{{
		$ctrl.live;
		$ctrl.refresh = function () {
			return $q.resolve().then(function () {
				return $loader.start($scope.$id, $ctrl.live === undefined);
			}).then(function () {
				return $http.get('/api/debug/live');
			}).then(function (res) {
				return $ctrl.live = res.data;
			}).then(function () {
				return $loader.stop($scope.$id);
			}).catch($toast.catch);
		};
		// }}}

		// Auto-refresh functionality {{{
		$ctrl.refreshTimer;
		$ctrl.autoRefresh = false;
		$ctrl.autoRefreshWorker = function () {
			$timeout.cancel($ctrl.refreshTimer);
			$ctrl.refreshTimer = $timeout(function () {
				return $ctrl.refresh().then(function () {
					return $ctrl.autoRefreshWorker();
				});
			}, 1000);
		};

		$scope.$watch('$ctrl.autoRefresh', function () {
			$timeout.cancel($ctrl.refreshTimer);
			if ($ctrl.autoRefresh) $ctrl.autoRefreshWorker();
		});
		// }}}

		$scope.$evalAsync(function () {
			return $ctrl.refresh();
		});
	}],
	template: '\n\t\t\t<ol class="breadcrumb">\n\t\t\t\t<li><a href="#/debug">Admin</a></li>\n\t\t\t\t<li><a href="#/debug/server">Server</a></li>\n\t\t\t\t<li><a href="#/debug/server/info">Info</a></li>\n\t\t\t</ol>\n\t\t\t<div id="page-content">\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading">\n\t\t\t\t\t\t<div class="panel-control">\n\t\t\t\t\t\t\t<a ng-click="$ctrl.autoRefresh = !$ctrl.autoRefresh" class="btn btn-sm" ng-class="$ctrl.autoRefresh ? \'btn-primary\' : \'btn-default\'">\n\t\t\t\t\t\t\t\tAuto\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t<a ng-click="$ctrl.refresh()" class="btn btn-sm" ng-class="$ctrl.$loader.loading ? \'btn-warning\' : \'btn-success\'">\n\t\t\t\t\t\t\t\t<i class="fa fa-sync" ng-class="$ctrl.$loader.loading && \'fa-spin\'"></i>\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<h3 class="panel-title">Server data</h3>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="panel-body">\n\t\t\t\t\t\t<pre>{{$ctrl.live | json}}</pre>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});