
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/users/invite').requires($session.promise.login).title('Invite').component('sessionInviteCtrl');
}]).component('sessionInviteCtrl', {
	templateUrl: '/units/session/invite.tmpl.html',
	controller: ['$scope', '$loader', '$location', '$toast', 'Users', function controller($scope, $loader, $location, $toast, Users) {
		var $ctrl = this;

		$ctrl.user = { email: '' };
		$ctrl.error;

		$ctrl.invite = function () {
			$loader.startBackground($scope.$id);

			Users.invite({
				emails: $ctrl.user.email.split(/[\s\n,]+/)
			}).$promise.then(function () {
				return $loader.stop($scope.$id);
			}).then(function () {
				return $toast.success('Invites sent');
			}).then(function (data) {
				return $location.path('/users');
			}).catch(function (res) {
				return $ctrl.error = res.data.error;
			});
		};
	}]
});