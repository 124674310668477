
/**
 * App client entry-point and initialiser
 */
angular.module('app', ['angular-bs-confirm', 'angular-bs-popover', 'angular-bs-tooltip', 'angular-mfdc-router', 'angular-ui-loader', 'ngAnimate', 'ngQuill', 'ngResource', 'ngSanitize', 'ngTreeTools', 'ui.gravatar', 'ui.select', 'ui-notification', 'uiToggle'])

// Global controller {{{
.controller('GlobalCtrl', ['$config', '$rootScope', '$router', '$scope', '$session', function ($config, $rootScope, $router, $scope, $session) {
	var $global = this;
	// Init user session object/data
	$global.$config = $config;
	$global.$router = $router;
	$global.$session = $session;
}])
// }}}

// Anglar core config {{{
// Enable async HTTP for performance boost {{{
.config(['$httpProvider', function ($httpProvider) {
	$httpProvider.useApplyAsync(true);
}])
// }}}

// Configure $location to not stupidly encode URLS {{{
.config(['$locationProvider', function ($locationProvider) {
	$locationProvider.hashPrefix(''); // URL Mode: http://domain/#/path
}])
// }}}

// Add additional methods to $location - $location.redirect {{{
.config(['$provide', function ($provide) {
	return $provide.decorator('$location', ['$delegate', function ($delegate) {
		/**
  * Add $location.redirect
  * This is a very simple wrapper around window.location but keeps things consistant by providing it within the $location handler
  * @param {string} url A full or partial URL to redirect to
  */
		$delegate.redirect = function (url) {
			return window.location = url;
		};
		return $delegate;
	}]);
}])
// }}}

// Add additional methods to $resource {{{
.config(['$injectorProvider', '$resourceProvider', function ($injectorProvider, $resourceProvider) {
	var $httpParamSerializerJQLike = $injectorProvider.$get().get('$httpParamSerializerJQLike');
	angular.extend($resourceProvider.defaults.actions, {
		count: {
			method: 'GET',
			params: {
				id: 'count'
			},
			paramSerializer: function paramSerializer(params) {
				return $httpParamSerializerJQLike(_.omit(params, [// Remove meta directives from request
				'limit', 'populate', 'select', 'skip', 'sort']));
			}
		},
		create: {
			method: 'POST'
		},
		meta: {
			method: 'GET',
			params: {
				id: 'meta',
				collectionEnums: true,
				prototype: true
			}
		}
	});
}])
// }}}

// Force $http headers via GET to not be cached {{{
.config(['$httpProvider', function ($httpProvider) {
	if (!$httpProvider.defaults.headers.get) $httpProvider.defaults.headers.get = {}; // Initialize get headers if not there

	// Override all Cache-Control
	$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
	$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
}])
// }}}

// Force $http query encoding to use the jQuery like encoder (necessary to work with complex objects with a Monoxide backend) {{{
.config(['$httpProvider', function ($httpProvider) {
	return $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike';
}])
// }}}

// Filekit config {{{
.run(['$filekit', '$loader', '$prompt', '$rootScope', '$toast', function ($filekit, $loader, $prompt, $rootScope, $toast) {
	// Setup FileKit to use the $toast error catcher
	$filekit.settings.errorHandler = function (err, files) {
		return $toast.catch(err);
	};

	// Setup FileKit to use $prompt.text() as a text prompt
	$filekit.settings.prompter = function (options) {
		return $prompt.text({
			title: options.title,
			body: options.body,
			default: options.default
		});
	};

	// Make $loader trigger when uploads start / end
	$rootScope.$on('filekitTaskBegin', function (e, id) {
		return $loader.startBackground(id);
	});
	$rootScope.$on('filekitTaskEnd', function (e, id) {
		return $loader.stop(id);
	});

	// Make $toast.progress handle file progression
	$rootScope.$on('filekitProgress', function (e, data) {
		return $toast.progress('filekit-upload-' + data.file.name, 'Uploading ' + data.file.name + '...', data.progress);
	});
	$filekit.settings.uploadStatus.enabled = false; // Disable the filekit method of showing status as the $toast.progress() function now handles it
}])
// }}}

// Disable the annoying 'possibly unhandled error' prompt {{{
.config(['$qProvider', function ($qProvider) {
	return $qProvider.errorOnUnhandledRejections(false);
}])
// }}}
// }}}

// Router config {{{

// Disable developer warnings if we're in production {{{
.run(['$config', '$rootScope', '$router', function ($config, $rootScope, $router) {
	if ($config.isProduction) {
		$router.warnings(false);
	} else {
		// We're not in production - attach to the debug handler just in cast thats enabled
		$rootScope.$on('routerDebug', function (e) {
			var _console;

			for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				args[_key - 1] = arguments[_key];
			}

			return (_console = console).log.apply(_console, ['ROUTER DEBUG:'].concat(args));
		});
	}
}])
// }}}

// Validate ':id' against a 24 bit hex string if seen in a URL {{{
.run(['$router', function ($router) {
	$router.tokenRule(':id', function (id) {
		return (/^[0-9a-f]{24}$/.test(id)
		);
	});
}])
// }}}

// Ensure all pages have a trailing slash when using a hashpath (e.g. `http://something#/something` -> `http://something/#/something {{{
.run(['$window', function ($window) {
	if ($window.location.pathname != '/' && $window.location.hash && !$window.location.pathname.endsWith('/')) {
		console.log('Redirect due to page not ending in slash');
		$window.location.pathname += '/';
	}
}])
// }}}

// Add helper classes to the body element when were routing {{{
.run(['$rootScope', '$timeout', function ($rootScope, $timeout) {
	// add .router-routing class to body when routing and remove it 1s after we have finished loading (i.e. everything has settled)
	$rootScope.$on('$routerStart', function () {
		return angular.element('body').addClass('router-routing');
	});
	$rootScope.$on('$routerSuccess', function () {
		return $timeout(function () {
			return angular.element('body').removeClass('router-routing');
		}, 1000);
	});
	$rootScope.$on('$routerError', function () {
		return $timeout(function () {
			return angular.element('body').removeClass('router-routing');
		}, 1000);
	});
}])
// }}}

// Animate the page loader while navigating {{{
.run(['$loader', '$rootScope', function ($loader, $rootScope) {
	$rootScope.$on('$routerStart', function () {
		return $loader.clear().start('routerNav');
	});
	$rootScope.$on('$routerSuccess', function () {
		return $loader.stop('routerNav');
	});
	$rootScope.$on('$routerError', function () {
		return $loader.stop('routerNav');
	});
}])
// }}}

// Cleanup Bootstrap elements on navigation / tooltips + popovers on scroll {{{
.run(['$rootScope', function ($rootScope) {
	$rootScope.$on('$routerStart', function () {
		// Destory any open Bootstrap modals
		$('body > .modal-backdrop').remove();

		// Destroy any open Bootstrap tooltips
		$('body > .tooltip').remove();

		// Destroy any open Bootstrap popovers
		$('body > .popover').remove();
	});
}])
// }}}

// When scrolling - kill BS location contextual elements {{{
// NOTE: To listen for scroll events use $scope.on('contentScroll')
// .run(function($rootScope) {
// 	var initWatcherUnbind = $rootScope.$on('$routerSuccess', ()=> { // Only register on first page load then destory
// 		angular.element('#content')[0].addEventListener('scroll', _.throttle(()=> { // Setup a throttled lister against scroll
// 			angular.element('body > .tooltip, body > .popover').remove();
// 			$rootScope.$apply(()=> $rootScope.$emit('contentScroll'));
// 		}, 100, {leading: true, trailing: false}), true);
// 		initWatcherUnbind();
// 	});
// })
// }}}

// Focus any input element post-navigation {{{
.run(['$rootScope', function ($rootScope) {
	$rootScope.$on('$routerSuccess', function () {
		return $('div[ui-view=main]').find('input[autofocus]').each(function () {
			this.selectionEnd = this.selectionStart = this.value.length;
		}).focus();
	});
}])
// }}}

// Reattach 'waves' effect on every router reload {{{
.run(['$rootScope', function ($rootScope) {
	$rootScope.$on('$routerSuccess', function () {
		// NOTE: All the below attachment rules assumes `waves-effect` is applied anyway
		$('.btn').addClass('waves-effect');
		$('.btn-circle, .btn-ellipsis').addClass('waves-circle');
		$('.input-group > .btn').addClass('waves-table-cell');
		$('a.list-group-items, .dropdown-menu > li > a, .main-menu > li > a').addClass('waves-effect waves-block');

		// Re-init
		Waves.init();
	});
}])
// }}}

// Set layout.isImportant = false on every router start
// Work around to prevent no header, no sidebar after reloading database
//{{{
.run(['$rootScope', '$config', function ($rootScope, $config) {
	$rootScope.$on('$routerStart', function () {
		$config.layout.isImportant = false;
	});
}])
// }}}

// Reload default layout settings on every router success {{{
.run(['$rootScope', '$config', function ($rootScope, $config) {
	$rootScope.$on('$routerSuccess', function () {
		if (!$config.layout.isImportant) {
			$config.layout.headerNavbar = true;
			$config.layout.sidebar = true;
			$config.layout.footer = false;
		}
	});
}])
// }}}

// Adjust page title when the page changes {{{
.run(['$config', '$rootScope', function ($config, $rootScope) {
	$rootScope.$on('$routerSuccess', function (e, rule) {
		return document.title = $config.title + (_.has(rule, '_data.title') ? ' | ' + rule._data.title : '');
	});
}])
// }}}

// Page load animation {{{
.run(['$rootScope', '$timeout', function ($rootScope, $timeout) {
	var previousRule;

	$rootScope.$on('$routerStart', function (e, rule) {
		previousRule = rule;
		angular.element('#content').css('opacity', 0);
	});

	$rootScope.$on('$routerSuccess', function (e, rule) {
		if (previousRule && previousRule._id && rule._id == previousRule._id) {
			// Navigating to self - skip animation
			angular.element('#content').css('opacity', 1);
			return;
		}

		var pageArea = angular.element('#content');
		pageArea.animate({
			opacity: 1
		}, {
			duration: 400,
			easing: 'swing',
			complete: function complete() {
				return pageArea.css({ opacity: '' });
			} // Remove mutated properties when we're done with them
		});
	});
}])
// }}}

// Scroll to top of page when navigating {{{
.run(['$loader', '$rootScope', function ($loader, $rootScope) {
	$rootScope.$on('$routerSuccess', function () {
		return $('#content').scrollTop(0);
	});
}])
// }}}

// Append $router.breadcrumbs() + $router.title to router {{{
.run(['$config', '$rootScope', '$router', function ($config, $rootScope, $router) {
	$router.page = { _title: $config.title, _breadcrumbs: [] };

	/**
 * Set the page title
 * This overrides any initial call to the routes title (via `$router.when(...).title('Something')`)
 * NOTE: Falsy arguments are ignored
 * @param {string,array} ...title The title or title segments to set. The main site title is automatically pre-pended
 * @return {$router.page} Chainable $router.page object
 */
	$router.page.title = function () {
		for (var _len2 = arguments.length, title = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
			title[_key2] = arguments[_key2];
		}

		$router.page._title = document.title = [$config.title].concat(_.flatten(title)).filter(function (i) {
			return !!i;
		}).reverse().join(' | ');

		return $router.page;
	};

	/**
 * Set the page breadcrumbs
 * NOTE: Falsy arguments are ignored
 * @param {array} ...breadcrumbs The breadcrumb segments to set. Each item should be an object of the form {link,title}
 * @return {$router.page} Chainable $router.page object
 */
	$router.page.breadcrumbs = function () {
		for (var _len3 = arguments.length, breadcrumbs = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
			breadcrumbs[_key3] = arguments[_key3];
		}

		$router.page._breadcrumbs = _.flatten(breadcrumbs).filter(function (i) {
			return !!i;
		});
		return $router.page;
	};

	$rootScope.$on('$routerStart', function () {
		// Reset tite + breadcrumbs on each page navigate
		$router.page._title = $config.title;
		$router.page._breadcrumbs = [];
	});
}])
// }}}

// }}}

// Theme {{{

// Disable animation effects on certain elements by default {{{
.config(['$animateProvider', function ($animateProvider) {
	$animateProvider.classNameFilter(/^((?!(fa-spinner|btn|brand-icon|modal|no-animate)).)*$/);
}])
// }}}

// Configure Selectize {{{
.config(['uiSelectConfig', function (uiSelectConfig) {
	uiSelectConfig.theme = 'selectize';
}])
// }}}

// Select any [autofocus] within a model after its finished animating {{{
.run(function () {
	return $(document).on('shown.bs.modal', function () {
		return $('.modal.in .modal-body [autofocus]').focus();
	});
})
// }}}

// Modals matching `.modal.in .modal-body table > tbody > tr` should move focus with up/down keys {{{
// Any model with a table in its `modal-body` will move focus when the up/down arrow keys are pressed - this makes navigating lists in modals a little easier
.run(function () {
	$(document, '.modal').keydown(function (e) {
		if (!$('body').hasClass('modal-open') || // No model open
		e.which != 38 && e.which != 40 || // Not up / down
		!$('.modal.in .modal-body > table').length // No table to select things from
		) return;
		e.preventDefault();

		var scrollTable = $('.modal.in .modal-body > table');

		var focusRow = scrollTable.find('tr:focus');

		if (!focusRow.length) {
			// No focus - select first item
			scrollTable.find('tbody').find('tr').first().focus();
		} else if (e.which == 38) {
			// Existing focus - Move up
			focusRow.prev().focus();
		} else if (e.which == 40) {
			// Existing focus - Move down
			focusRow.next().focus();
		}
	});
})
// }}}

// Remove bootstraping body class now everything is ready {{{
.run(['$timeout', function ($timeout) {
	return $timeout(function () {
		return $('body').removeClass('bootstraping');
	});
}])
// }}}
// }}}

// Global config for Quill wysiwyg response boxes {{{
.config(['ngQuillConfigProvider', function (ngQuillConfigProvider) {
	var config = {
		modules: {
			imageDrop: true
		}
	};

	ngQuillConfigProvider.set(config);
}]);
// }}}

// }}}