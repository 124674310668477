
angular.module('app').run(['$router', function ($router) {
	return $router.when('/debug/services/http').component('debugServicesHttpCtrl');
}]).component('debugServicesHttpCtrl', {
	controller: ['$http', '$loader', '$q', '$toast', function controller($http, $loader, $q, $toast) {
		var $ctrl = this;

		$ctrl.fetch = function (url) {
			$q.resolve().then(function () {
				return $http.get(url);
			}).then(function (res) {
				return $toast.success(res.data);
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};
	}],
	template: '\n\t\t\t<ol class="breadcrumb">\n\t\t\t\t<li><a href="#/debug">Admin</a></li>\n\t\t\t\t<li><a href="#/debug/services">Services</a></li>\n\t\t\t\t<li><a href="#/debug/services/http">$http</a></li>\n\t\t\t</ol>\n\t\t\t<div id="page-content">\n\t\t\t\t<div class="panel panel-default">\n\t\t\t\t\t<div class="panel-heading">\n\t\t\t\t\t\t<h3 class="panel-title">$http</h3>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="panel-body">\n\t\t\t\t\t\t<p>Simple controller showing the result of vairious back-end HTTP response codes and how they are automatically handled by <a href="#/debug/services/toast">$toast</a></p>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="list-group">\n\t\t\t\t\t\t<a class="list-group-item" ng-click="$ctrl.fetch(\'/api/debug/200\')">Trigger Code 200</a>\n\t\t\t\t\t\t<a class="list-group-item" ng-click="$ctrl.fetch(\'/api/debug/403\')">Trigger Code 403</a>\n\t\t\t\t\t\t<a class="list-group-item" ng-click="$ctrl.fetch(\'/api/debug/500\')">Trigger Code 500</a>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});