
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/author-tool').requires($session.promise.notLogin).title('Author tool').component('authorToolCtrl');
}]).run(['$router', '$session', function ($router, $session) {
	return $router.when('/gen/:shortid').require($session.promise.notLogin).title('View generated form').component('authorToolCtrl');
}]).component('authorToolCtrl', {
	templateUrl: '/units/site/generator.tmpl.html',
	controller: ['$scope', '$clipboard', '$location', '$loader', '$router', '$sce', '$timeout', '$toast', '$window', 'Generator', function controller($scope, $clipboard, $location, $loader, $router, $sce, $timeout, $toast, $window, Generator) {
		var $ctrl = this;

		$ctrl.generation = {};
		$ctrl.generation.answers = [];
		$ctrl.generation.type = 'trial';
		$ctrl.generation.reason = 'intervention';
		$ctrl.generation.details = '';
		$ctrl.$router = $router;

		$ctrl.checklist = [{
			label: "Brief name",
			description: "Provide the name or a phrase that describes the intervention.",
			examples: ['TREAD (TREAtment of Depression with physical activity) study.'],
			explanation: 'Explanation—Precision in the name, or brief description, of an intervention enables easy identification of the type of intervention and facilitates linkage to other reports on the same intervention. Give the intervention name, explaining any abbreviations or acronyms in full, or a short (one or two line) statement of the intervention without elaboration.'
		}, {
			label: "Why",
			description: "Describe any rationale, theory, or goal of the elements essential to the intervention.",
			examples: ['Self-management of oral anticoagulant therapy may result in a more individualised approach, increased patient responsibility, and enhanced compliance, which may lead to improvement in the regulation of anticoagulation.'],
			explanation: 'Explanation—Inclusion of the rationale, theory, or goals that underpin an intervention, or the components of a complex intervention, can help others to know which elements are essential, rather than optional or incidental. For example, the colour of capsules used in a pharmacological intervention is likely to be an incidental, not essential, contributor to the intervention’s efficacy and hence reporting of this is not necessary. In some reports, the term “active ingredient” is used and refers to the components within an intervention that can be specifically linked to its effect on outcomes such that, if they were omitted, the intervention would be ineffective. The known or supposed mechanism of action of the active component/s of the intervention should be described.'
		}, {
			label: "What (material)",
			description: "Describe any physical or informational materials used in the intervention, including those provided to participants or used in intervention delivery or in training of intervention providers. Provide information on where the materials can be accessed (for example, online appendix, URL).",
			examples: ['The intervention group received a behaviour change counselling training programme called the Talking Lifestyle learning programme that took practitioners through a portfolio-driven set of learning activities. Precise details of both intervention content and the training programme can be found in [URL, login and password provided]. . . Box 1 provides a more detailed description of the components of the training programme.'],
			explanation: 'Explanation—A full description of an intervention should describe what different physical and information materials were used as part of the intervention (this typically will not extend to study consent forms unless they provide written instructions about the intervention that are not provided elsewhere). Intervention materials are the most commonly missing element of intervention descriptions. This list of materials can be regarded as comparable with the “ingredients” required for a recipe. It can include materials provided to participants, training materials used with the intervention providers, or the surgical device or pharmaceutical drug used and its manufacturer. For some interventions, it might be possible to describe the materials and the procedures together.'
		}, {
			label: "What (procedures)",
			description: "Describe each of the procedures, activities, and/or processes used in the intervention, including any enabling or support activities.",
			examples: ['The TREPP [transrectus sheath preperitoneal] technique can be performed under spinal anaesthesia. To reach the PPS [preperitoneal space], a 5 cm straight incision is made about 1 cm above the pubic bone. The anterior rectus sheath is opened, as is the underlying fascia transversalis [figure]. After retraction of the muscle fibres medially, the inferior epigastric vein and artery are identified and retracted medially as well'],
			explanation: 'Explanation—Describe what processes, activities, or procedures the intervention provider/s carried out. Continuing the recipe metaphor used in Q3, this item refers to the “methods” section of a recipe and where intervention materials (“ingredients”) are involved, describes what is to be done with them. “Procedure” can refer to the sequence of steps to be followed and is a term used by some disciplines, particularly surgery, and includes, for example, preoperative assessment, optimisation, type of anaesthesia, and perioperative and postoperative care, along with details of the actual surgical procedure used. Examples of processes or activities include referral, screening, case finding, assessment, education, treatment sessions, telephone contacts, etc. Some interventions, particularly complex ones, might require additional activities to enable or support the intervention to occur (in some disciplines these are known as implementation activities), and these should also be described. Elaboration about how to report interventions where the procedure is not the same for all participants is provided at item 9 (tailoring).'
		}, {
			label: "Who provided",
			description: "For each category of intervention provider (for example, psychologist, nursing assistant), describe their expertise, background and any specific training given.",
			examples: ['The procedure is simple, uses existing surgical skills, and has a short learning curve, with the manufacturers recommending at least five mentored cases before independently practising. All surgeons involved in the study will have completed this training and will have carried out over five procedures prior to recruiting to the study'],
			explanation: 'Explanation—The term “intervention provider” refers to who was involved in providing the intervention (for example, by delivering it to recipients or undertaking specific tasks). This is important in circumstances where the providers’ expertise and other characteristics could affect the outcomes of the intervention. Important issues to address in the description might include the number of providers involved in delivering or undertaking the intervention; their disciplinary background (for example, nurse, occupational therapist, colorectal surgeon, expert patient); what pre-existing specific skills, expertise, and experience providers required and if and how these were verified; details of any additional training specific to the intervention that needed to be given to providers before and/or during the study; and if competence in delivering the intervention was assessed before or monitored throughout the study and whether those deemed lacking in competence were excluded or retrained. Other information about providers could include whether the providers were doing the intervention as part of their normal role or were specially recruited as providers for purposes of the study; whether providers were reimbursed for their time or provided with other incentives (if so, what) to deliver the intervention as part of the study, and whether such time or incentives might be needed to replicate the intervention.'
		}, {
			label: "How (mode of delivery; individual or group)",
			description: "Describe the modes of delivery (such as face to face or by some other mechanism, such as internet or telephone) of the intervention and whether it was provided individually or in a group.",
			examples: ['. . . three 1 hour home visits (televisits) by a trained assistant . . . ; participants’ daily use of an in-home messaging device . . .… that was monitored weekly by the teletherapist; and five telephone intervention calls between the teletherapist and the participant . . .'],
			explanation: 'Explanation—Specify whether the intervention was provided to one participant at a time (such as a surgical intervention) or to a group of participants and, if so, the group size. Also describe whether it was delivered face to face, by distance (such as by telephone, surface mail, email, internet, DVD, mass media campaign, etc), or a combination of modes. When relevant, describe who initiated the contact, and whether the session was interactive or not, and any other delivery features considered essential or likely to influence outcome.'
		}, {
			label: "Where",
			description: "Describe the type(s) of location(s) where the intervention occurred, including any necessary infrastructure or relevant features.",
			examples: ['Most births in African countries occur at home, especially in rural areas . . . They identified pregnant women and made five home visits during and after pregnancy . . . Peer counsellors lived in the same communities, so informal contacts to make arrangements for visits were common. . . counsellors were . . . given a bicycle, T shirt. . .'],
			explanation: 'Explanation—In some studies the intervention can be delivered in the same location where participants were recruited and/or data were collected and details might therefore already be included in the primary paper (for example, as in item 4b of CONSORT 2010 statement if reporting a trial). If, however, the intervention occurred in different locations, this should be specified. At its simplest level, the location might be, for example, in the participants’ home, residential aged care facility, school, outpatient clinic, inpatient hospital room, or a combination of locations. Features or circumstances about the location can be relevant to the delivery of the intervention and should be described. For example, they might include the country, type of hospital or primary care, publicly or privately funded care, volume of activity, details of the healthcare system, or the availability of certain facilities or equipment. These features can impact on various aspects of the intervention such as its feasibility or provider or participant adherence and are important for those considering replicating the intervention.'
		}, {
			label: "When and how much",
			description: "Describe the number of times the intervention was delivered and over what period of time including the number of sessions, their schedule, and their duration, intensity or dose.",
			examples: ['8a. They received five text messages a day for the first five weeks and then three a week for the next 26 weeks.', '8b . . . . exercise three times a week for 24 weeks. . . Participants began with 15 minutes of exercise and increased to 40 minutes by week eight . . . Between weeks eight and 24, attempts to increase exercise intensity were made at least weekly either by increasing treadmill speed or by increasing the treadmill grade. Participants with leg symptoms were encouraged to exercise to near maximal leg symptoms. Asymptomatic participants were encouraged to exercise to a level of 12 to 14 . . . . on the Borg rating of perceived exertion scale [reference].'],
			explanation: 'Explanation—The type of information needed about the “when and how much” of the intervention will differ according to the type of intervention. For some interventions some aspects will be more important than others. For example, for pharmacological interventions, the dose and scheduling is often important; for many non-pharmacological interventions, the “how much” of the intervention is instead described by the duration and number of sessions. For multiple session interventions, the schedule of the sessions is also needed and if the number of sessions, their schedule, and/or intensity was fixed or if it could be varied according to rules and if so, what they were. Tailoring of the intervention to individuals or groups of individuals is elaborated on in item 9 (tailoring). For some interventions, as part of the “when” information, detail about the timing of the intervention in relation to relevant events might also be important (for example, how long after diagnosis, first symptoms, or a crucial event did the intervention start). As described below in item 12, the “amount” or dose of intervention that participants actually received might differ from the amount intended. This detail should be described, usually in the results section.'
		}, {
			label: "Tailoring",
			description: "In tailored interventions, not all participants receive an identical intervention. Was this intervention planned to be personalised, titrated or adapted? [Yes / NA]",
			examples: ['Participants began exercising at 50% of their 1 rm [repetition maximum]. Weights were increased over the first five weeks until participants were lifting 80% of their 1 rm. Weights were adjusted after each monthly 1 rm and as needed to achieve an exercise intensity of a rating of perceived exertion of 12 to 14.'],
			explanation: 'Explanation—In tailored interventions, not all participants receive an identical intervention. Interventions can be tailored for several reasons, such as titration to obtain an appropriate “dose”; participant’s preference, skills, or situation; or it may be an intrinsic element of the intervention as with increasing intensity of an exercise. Hence, a brief rationale and guide for tailoring should be provided, including any variables/constructs used for participant assessment and subsequent tailoring. Tailoring can occur at several stages and authors should describe any decision points and rules used at each point. If any decisional or instructional materials are used, such as flowcharts, algorithms or dosing nomograms, these should be included and referenced, or their location provided.'
		}, {
			label: "Modification",
			description: "Unforeseen modifications to the intervention can occur during the course of the study, particularly in early studies. Was this intervention modified during the course of the study? [Yes / NA]",
			examples: ['A mixture of general practitioners and practice care nurses delivered 95% of screening and brief intervention activity in this trial. . . Owing to this slow recruitment, research staff who had delivered training in study procedures supported screening and brief intervention delivery in 10 practices and recruited 152 patients, which was 5% of the total number of trial participants.'],
			explanation: 'Explanation— This item refers to modifications that occur at the study level, not individual tailoring as described in item 9. Unforeseen modifications to the intervention can occur during the course of the study, particularly in early studies. If this happens, it is important to explain what was modified, why and when modifications occurred, and how the modified intervention differed from the original. Modifications sometimes reflect changing circumstances. In other studies, they can show learning about the intervention, which is important to transmit to the reader and others to prevent unnecessary repetition of errors during attempts to replicate the intervention. If changes to the intervention occurred between the published protocol or published pilot study and the primary paper, these changes should also be described.'
		}, {
			label: "How well (planned)",
			description: "Fidelity refers to the degree to which an intervention happened in the way the investigators intended it to. This item—and item 12—extends beyond simple receipt of the intervention (such as how many participants were issued with the intervention drug or exercises) and refers to “how well” the intervention was received or delivered (such as how many participants took the drug/did the exercises, how much they took/did, and for how long).",
			examples: ['Adherence to trial medication was assessed by means of self-reported pill counts collected during follow-up telephone calls. These data were categorised as no pills taken, hardly any taken (1-24% of prescribed doses), some taken (25-49%), most taken (50-74%), or all taken (75-100%).'],
			explanation: 'Explanation—Fidelity refers to the degree to which an intervention happened in the way the investigators intended it to and can affect the success of an intervention. The terms used to describe this concept vary among disciplines and include treatment integrity, provider or participant adherence, and implementation fidelity. This item—and item 12—extends beyond simple receipt of the intervention (such as how many participants were issued with the intervention drug or exercises) and refers to “how well” the intervention was received or delivered (such as how many participants took the drug/did the exercises, how much they took/did, and for how long). Depending on the intervention, fidelity can apply to one or more parts of the intervention, such as training of providers, delivery of the intervention, and receipt of the intervention. The types of measures used to determine intervention fidelity will also vary according to the type of intervention. For example, in simple pharmacological interventions, assessing fidelity often focuses on recipients’ adherence to taking the drug. In complex interventions, such as rehabilitation, psychological, or behaviour change interventions, however, assessment of fidelity is also more complex. There are various preplanned strategies and tools that can be used to maintain fidelity before delivery of the intervention or during the study. If any strategies or tools were used to maintain fidelity, they should be clearly described. Any materials used as part of assessing or maintaining fidelity should be included, referenced, or their location provided.'
		}, {
			label: "How well (actual)",
			description: "For various reasons, an intervention, or parts of it, might not be delivered as intended, thus affecting the fidelity of the intervention. IF intervention adherence or fidelity was assessed, describe the extent to which the intervention was delivered as planned.",
			examples: ['12a. The mean (SD) number of physiotherapy sessions attended was 7.5 (1.9). Seven patients (9%) completed less than four physiotherapy sessions; the reasons included non-attendance, moving interstate, or recovery from pain. Of patients in the physiotherapy groups, 70% were compliant with their home exercise program during at least five of seven weeks.', '12b. A total of 214 participants (78%) reported taking at least 75% of the study tablets; the proportion of patients who reported taking at least 75% of the tablets was similar in the two groups.'],
			explanation: 'Explanation— For various reasons, an intervention, or parts of it, might not be delivered as intended, thus affecting the fidelity of the intervention. If this is assessed, authors should describe the extent to which the delivered intervention varied from the intended intervention. This information can help to explain study findings, minimise errors in interpreting study outcomes, inform future modifications to the intervention, and, when fidelity is poor, can point to the need for further studies or strategies to improve fidelity or adherence. For example, there might be some aspects of the intervention that participants do not like and this could influence their adherence. The way in which the intervention fidelity is reported will reflect the measures used to assess it, as described in item 11.'
		}];

		// Init answers array
		_.forEach($ctrl.checklist, function (value) {
			$ctrl.generation.answers.push('');
		});

		$ctrl.refresh = function () {
			if ($router.params.shortid) {
				$loader.start($scope.$id);
				Generator.get({ shortid: $router.params.shortid }).$promise.then(function (data) {
					$ctrl.generation.answers = data.answers;
					$ctrl.generation.type = data.type;
					$ctrl.generation.reason = data.reason;
					$ctrl.generation.details = data.details;
				}).catch(function (e) {
					if (e.status && e.status == 404) $location.path('/generator');
				}).finally(function () {
					return $loader.stop($scope.$id);
				});
			}
		};

		$ctrl.save = function () {
			$loader.start($scope.$id);
			Generator.save({ shortid: $router.params.shortid }, $ctrl.generation).$promise.then(function (res) {
				$toast.success('Form saved');
				$location.path('/gen/' + res.shortid);
			}).catch($toast.catch).finally(function () {
				return $loader.stop($scope.$id);
			});
		};

		$ctrl.copy = function () {
			var y = $window.scrollY;
			$clipboard.copy('tidierguide.org/#/gen/' + $ctrl.$router.params.shortid, true);
			$window.scrollTo(0, y);
		};

		$scope.$evalAsync($ctrl.refresh);
	}]
});