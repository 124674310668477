
angular.module('app').run(['$router', '$session', function ($router, $session) {
	return $router.when('/contact').title('Contact').requires($session.promise.notLogin).component('contactPageCtrl');
}]).component('contactPageCtrl', {
	templateUrl: '/units/site/contact.tmpl.html',
	controller: ['$scope', 'Site', function controller($scope, Site) {
		var $ctrl = this;

		$ctrl.message = {
			name: '',
			email: '',
			body: ''
		};

		$ctrl.refresh = function () {};

		$ctrl.submit = function () {
			Site.contact($ctrl.message).$promise.then(function () {
				// Show success confirmation modal
				$('#contact-modal-success').modal('show');

				// Clear form fields
				$ctrl.message = {
					name: '',
					email: '',
					body: ''
				};
			}).catch(function (err) {
				$ctrl.error = _.get(err, 'data.error') || err.error || err || 'Could not send message';
				$('#contact-modal-fail').modal('show');
			});
		};

		$scope.$evalAsync($ctrl.refresh);
	}]
});