
/**
* File upload drop area which uses the $filekit upload service
*
* @param {string} [element] The jQuery element to bind to, if omitted the document body is used (be careful to only use one of these elements on a page if the body is the drop area)
* @param {function} [onStart] Optional function to run as ({files}) when the upload(s) start
* @param {function} [onComplete] Optional function to run as ({files}) when the upload(s) complete
* @param {string} url URL endpoint to upload to
*
* @example Create a drop area which automatically uploads when the user drops a file on the page
* <filekit-drop-area url="/some/url"></filekit-drop-area>
*/
angular.module('app').component('filekitDropArea', {
	bindings: {
		element: '@',
		onComplete: '&?',
		onStart: '&?',
		url: '@'
	},
	controller: ['$element', '$filekit', '$q', '$scope', '$timeout', function controller($element, $filekit, $q, $scope, $timeout) {
		var $ctrl = this;

		$ctrl.dropAreaTimer;
		$ctrl.showDropArea = function (show) {
			// Since the dragover event is a bit slow we have to wait for a timeout before we are allowed to remove the droparea indicator
			var dropArea = $element.children('.droparea');
			if (show) {
				$timeout.cancel($ctrl.dropAreaTimer);
				dropArea.show();
			} else {
				$ctrl.dropAreaTimer = $timeout(function () {
					return dropArea.hide();
				}, 250);
			}
		};

		$ctrl.$onInit = function () {
			var boundElem = angular.element($ctrl.element || 'body');

			// Show and hide the drop prompt when the user drags files over the bound element
			boundElem[0].addEventListener('dragover', function (e) {
				return $ctrl.showDropArea(true);
			}, false);
			boundElem[0].addEventListener('dragleave', function (e) {
				return $ctrl.showDropArea(false);
			}, false);

			$element.children('.droparea').on('dragover', function (e) {
				return e.preventDefault();
			}) // We have to trap the dragover event of the handler or the 'drop' event wont fire (see https://stackoverflow.com/a/27482540)
			.on('drop', function (e) {
				e.stopPropagation();
				e.preventDefault();

				var uploadPromise = $q.resolve();
				var files = e.originalEvent.dataTransfer.files;
				if (_.isFunction($ctrl.onStart)) uploadPromise.then(function () {
					return $ctrl.onStart({ files: files });
				});
				uploadPromise.then(function () {
					return $filekit.upload($ctrl.url, files);
				});
				if (_.isFunction($ctrl.onComplete)) $q.all(uploadPromise).then(function (upload) {
					return $ctrl.onComplete({ files: files });
				});

				$ctrl.showDropArea(false);
			});
		};
	}],
	template: '\n\t\t\t<div class="droparea" style="display: none">\n\t\t\t\t<div class="vcenter-outer">\n\t\t\t\t\t<div class="vcenter-inner text-center">\n\t\t\t\t\t\tDrop files to upload\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t'
});